/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Newsletter {
  background: $skinLighter;
  color: $grey;
  position: relative;
  text-align: center;

  > div {
    background-position: 50% 50%;
    background-size: cover;
    padding-bottom: 44px;
    padding-top: 44px;
    position: relative;

    &:before,
    &:after {
      background: linear-gradient(90deg, $skinLighter, hsla(0, 0%, 100%, 0));
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 150px;
    }

    &:after {
      background: linear-gradient(270deg, $skinLighter, hsla(0, 0%, 100%, 0));
      left: unset;
      right: 0;
    }
  }

  &:after {
    box-shadow: inset 0 0 4px rgba(33, 34, 36, 0.24);
    content: '';
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &.NewsletterSuccessful {

    .NewsletterTitle {
      margin-bottom: 30px;
    }
  }
}

.NewsletterTitle {
  font-family: $fontFamily;
  font-size: 48px;
  line-height: 64px;
  margin-bottom: 15px;
}

.NewsletterText {
  font-size: $fontSize-l;
  line-height: 20px;
  margin: 0 auto 30px;
  max-width: 520px;

  span {
    font-weight: $font-weight-l;
  }
}

.NewsletterFormInputs {
  background: $white;
  border-radius: 8px 24px 24px 8px;
  box-shadow: 0 2px 2px rgba(33, 34, 36, 0.24);
  height: 48px;
  margin: 0 auto 25px;
  max-width: 627px;
  position: relative;

  label {
    display: none;
  }

  form {
    padding-top: 4px;
  }

  button {
    position: absolute;
    right: 4px;
    top: 4px;
    width: 200px;
  }
}

.NewsletterFormInputsThin {

  button {
    width: 126px;
  }
}

.NewsletterFormInputsNotifier {
  box-shadow: none;
}

.NewsletterTextAdditional {
  font-size: $fontSize-s;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 680px;

  span {
    cursor: pointer;
    text-decoration: underline;
  }
}

.NewsletterSuccess {
  background: $white;
  border-radius: 8px;
  box-shadow: 0 2px 2px rgba(33, 34, 36, 0.24);
  color: $greyDarkest;
  margin: 20px auto 25px;
  max-width: 627px;
  padding: 24px 10px;
}

.NewsletterSuccessHeader {
  font-size: $fontSize-xl;
  line-height: 26px;
  margin-bottom: 11px;
}

.NewsletterSuccessText {
  font-size: $fontSize-sm;
  line-height: 22px;
}

.NewsletterForm {
  position: relative;

  :global {

    .themed-form-field-error {
      color: $redError;
      font-size: $fontSize-s;
      font-weight: $font-weight-l;
      letter-spacing: 0.5px;
      line-height: 15px;
      margin-bottom: 0;
      margin-left: 0;
      position: absolute;
      top: 54px;
      width: 100%;

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
}

.NewsletterErrorMessage {
  color: $redError;
  font-size: $fontSize-s;
  font-weight: $font-weight-l;
  letter-spacing: 0.5px;
  line-height: 15px;
  margin-bottom: -12px;
  margin-top: 10px;
  width: 100%;
}

.NewsletterFormInputsWidget {
  box-shadow: none;
  height: auto;
  margin-left: 0;

  button[ type = 'submit' ] {
    margin-top: 15px;
    position: relative;
  }

  .NewsletterFormRadioContainer {

    > div {
      display: flex;
      margin-bottom: 24px;

      > div + div {
        margin-left: 20px;
      }
    }
  }

  label {
    display: inline-block;
  }

  .NewsletterErrorMessage {
    margin-top: 0;
    top: -4;
  }

  :global {

    .themed-form-field-error {
      font-weight: $font-weight-m;
      margin-bottom: 5px;
      position: relative;
      top: 0;
    }
  }
}

.LegalHolder {
  font-size: $fontSize-sm;
  font-weight: $font-weight-m;
  padding: 20px 0;

  a {
    text-decoration: underline;

    &:hover {
      color: $purple;
      text-decoration: none;
    }
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .Newsletter {

    > div {
      padding-bottom: 35px;
      padding-top: 44px;

      &:before,
      &:after {
        display: none;
      }
    }
  }

  .NewsletterFormInputs {
    max-width: 535px;
  }

  .NewsletterSuccess {
    max-width: 535px;
  }

  .NewsletterTextAdditional {
    max-width: 557px;
  }

  .NewsletterFormInputsThin {

    button {
      width: 100px;
    }
  }
}

@media screen and ( $mobile-media ) {

  .NewsletterForm {

    :global {

      .themed-form-field-error {
        bottom: -8px;
        letter-spacing: 0;
        padding: 0;
        position: relative;
        top: auto;
      }
    }
  }

  .NewsletterTitle {
    font-size: $fontSize-xl;
    line-height: 26px;
  }

  .NewsletterText {
    margin-bottom: 16px;
  }

  .NewsletterFormInputs {
    border-radius: 8px;
    margin-bottom: 80px;

    button {
      margin: 0 auto;
      position: relative;
      top: 15px;
    }
  }

  .NewsletterFormInputsThin {

    button {
      width: 150px;
    }
  }

  .NewsletterHide {
    display: none;
  }
}
